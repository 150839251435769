
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ClientInvoicesList from "@/components/reusable/invoices/ClientInvoicesList.vue";

export default defineComponent({
  name: "broker-client-invoices",
  components: { ClientInvoicesList },
  data() {
    return {
      invoices: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Clients Invoices");
    });
  },
  created() {
    //Set page title
    document.title = "Client Invoices | " + process.env.VUE_APP_TITLE;

    // Get Client invoices
    this.getClientInvoices();
  },
  methods: {
    getClientInvoices() {
      //Fetch list individual clients cart items
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BROKER_CLIENT_INVOICES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.invoices = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
