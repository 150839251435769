
import { defineComponent, watch } from "vue";
import variables from "@/router/api";
import BrokerInvoicesDropdownOptions from "@/components/individual/BrokerInvoicesDropdownOptions.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "invoices-list",
  components: { BrokerInvoicesDropdownOptions },
  props: {
    invoices: Array,
  },
  setup(props) {
    const { isEmptyArray, displayDatatable } = ResusableFunctions();

    watch(
      () => props.invoices,
      (newInvoicies: any) => {
        if (!isEmptyArray(newInvoicies)) {
          displayDatatable(["basicExample"]);
        }
      }
    );
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
